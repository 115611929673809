/* eslint-disable no-irregular-whitespace */
import React from 'react';

import { css, Theme } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import SectionTitle from '@/components/atoms/common/SectionTitle';

const secondOpinionDescWrap = (theme: Theme) => css({
  backgroundColor: theme.palette.background.default,
  padding: '2rem 0'
});

const secondOpinionDescBox = (theme: Theme) => css({
  width: '90%',
  margin:  '0 auto',
  [theme.breakpoints.up('md')]: {
    width: '80%',
    maxWidth: '960px'
  }
});

const secondOpinionDesc = css({
  margin: '1rem auto',
  lineHeight: 2
});

const brAdjust = css({
  display: 'inline-block',
});

const strong = css({
  fontWeight: 600
});

const SecondOpinionDesc: React.VFC = () => {
  return (
    <section css={secondOpinionDescWrap}>
      <Box css={secondOpinionDescBox}>
        <Typography variant="h2" align="center">
          <span css={brAdjust}>株式会社 富士見工務店が考える</span>
          <span css={brAdjust}>セカンドオピニオンとは？</span>
        </Typography>
        <Typography css={secondOpinionDesc}>
          建物やリフォームに関する心配や不安に思うことをご相談ください。
          <br />
          例えば、
          <br />
          <span css={strong}>「リフォームは他社でやるけど、だまされたくない！」</span>
          <br />
          <span css={strong}>「不安に思うことを第三者の建築会社に聞いてみたい！」</span>
          <br />
          など。
          <br />
          私たちは、ご相談いただいたからといって営業や見積参加などは絶対にいたしません。
          <br />
          あなた様が大金をかけて新築やリフォームをするわけですから、
          <br />「だまされたり」「悲しい思いをしたり」しないよう、ご相談いただければアドバイスいたします。
          <br />
          ご相談は無料です。
        </Typography>
        <Typography css={secondOpinionDesc}>
          相談内容によってはご回答しかねる場合もございます。
          <br />
          予めご了承ください。
          <br />
          例：〇〇建設の評判を教えてほしい。
          <br />
          　　この間取りがベストか考えてほしい。
          <br />
          　　建設会社や設計事務所などの人間関係による相談。
        </Typography>
      </Box>
    </section>
  );
};

export default SecondOpinionDesc;
