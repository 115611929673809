import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { css, Theme } from '@emotion/react';

import Layout from '../layouts/Layout';
import FirstView from '@/components/organisms/common/FirstView';
import OverviewBand from '@/components/molecules/secondOpinion/OverviewBand';
import SecondOpinionDesc from '@/components/organisms/secondOpinion/SecondOpinionDesc';
import ContactLinkBlock from '@/components/organisms/common/ContactLinkBlock';
import BottomMenu from '@/components/organisms/common/BottomMenu';
import Seo from '@/components/organisms/common/Seo';

const FirstViewImage = (theme: Theme) => css({
  height: '25vh',
  [theme.breakpoints.down('xs')]: {
    height: '20vh',
  }
});
const SecondOpinionPage: React.VFC<PageProps> = () => {
  return (
    <Layout>
      <Seo
        title="建築セカンドオピニオン"
        description="株式会社 富士見工務店では、他社様でのお見積りや各種ご質問について、セカンドオピニオンを受け付けています。"
      />
      <FirstView
        titleEn="SECOND OPINION"
        title="建築セカンドオピニオン"
        titlePosition="left"
      >
        <StaticImage
          alt="SecondOpinionImage"
          src="../images/firstviews/secondOpinion.jpg"
          css={FirstViewImage}
          layout="fullWidth"
          objectPosition="50% 55%"
        />
      </FirstView>
      <OverviewBand />
      <SecondOpinionDesc />
      <ContactLinkBlock />
      <BottomMenu />
    </Layout>
  );
};

export default SecondOpinionPage;
